import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"; 
import { Row, Col, Button, Modal, Spin, Alert, Select, Space, Divider, message } from "antd";
import { SearchOutlined, DoubleRightOutlined } from '@ant-design/icons';
// import { Link } from "react-router-dom";
import moment from "moment";
import cx from "classnames";
import { selectUser } from "../redux/slices/user";
import { ReactComponent as Cash } from "../assets/icons/cash.svg";
import { ReactComponent as Card } from "../assets/icons/card.svg";
import { ReactComponent as Driver } from "../assets/icons/driver.svg";
import { ReactComponent as PeyaCourierLogo } from "../assets/logo/pedidos-ya-courrier.svg";
// import { ReactComponent as Cart } from "../assets/icons/cart.svg";
// import { ReactComponent as Check } from "../assets/icons/check.svg";
// import { ReactComponent as Cancel } from "../assets/icons/cancel.svg";
import Close from "../components/Close";
import styles from "./styles/OrderDetail.module.css";
import instance from "../api/Request";
import { getLastScannedThunk } from "../redux/slices/scanner";

 const { Option } = Select;
// const { TextArea } = Input;

const OrderDetail = (props) => {
  const [drivers, handleDrivers] = useState([]);
  const [selectDriver, handleSelectedDriver] = useState(null);
  const [statusPeya, setStatusPeya] = useState(null);
  // const [selectBranch, handleSelectedBranch] = useState(null);
  const [loading, handleLoading] = useState(true);
  const [sending, handleSending] = useState(false);
  const [assing, handleAssing] = useState(false);
  const [deleting, handleDeleting] = useState(false);
  const [loadingDeta, handleLoadingOrder] = useState(false);
  // const [updatingNotes, setUpdatingNotes] = useState(false);
  // const [notes, setNotes] = useState("");
  const user = useSelector(selectUser);
  const dispatch = useDispatch()
  const isAdmin = user.admin;
  const isDriver = user.driver;

  const {
    order,
    monitor,
    close,
    acceptOrder,
    updateOrder,
    finishOrder,
    cancelOrder,
    assignOrder,
    resendOrder,
	sendPeya,
    // openModalCommetaries,
    getDriversBranch,
    openDriverConfirm,
    // userView,
    // branches,
    // transferOrder,
    // updateNotes,
  } = props;

  const {
    branch,
    status,
    orderType,
    id_user,
    id_driver,
    delivery_address,
    address_info,
    driver,
    provisional_driver,
    discount,
    total_usd,
    total,
    ticket,
    peyaOrder,
    products,
    cireba_remote_id,
    payment_method,
    change,
    cashback,
    delivery_fee,
    delivery_tip,
    flotiOrder,
    peyaStatus,
    peyaConfirmCode,
    currency
  } = order;

  const isCash = payment_method === 1;

  // console.log(order);

  useEffect(() => {
    const initialFect = async () => {
      if (monitor) {
        const newDrivers = await getDriversBranch(isAdmin ? 0 : branch.id);
        handleDrivers(newDrivers);
        handleLoading(false);
        if (id_driver) {
          handleSelectedDriver(id_driver.id);
        }
      }
     
    };
    initialFect();
  }, [order]);

  const getStatus = () => {
      if (status === 6) {
        return "orden nueva";
      } 
      else if (status === 7) {
        return "en preparación";
      } 
      else if (status === 8) {
        return "Asignada a driver";
      }
      else if (status === 9) {
        return "Driver en domicilio";
      }
      else if (status === 10) {
        return "lista para domicilio";
      } 
      else if (status === 11) {
        return (orderType=="1"||orderType=="0")?"lista para recoger":"En camino";
      } 
      else if (status === 12) {
        return "completada";
      }

      return "cancelada";
  };

  const spanish_peya = {
    'CONFIRMED': 'CONFIRMADA',
    'IN_PROGRESS': 'INICIADA',
    'NEAR_PICKUP': 'LLEGADA SUCURSAL',
    'PICKED_UP': 'EN CAMINO',
    'NEAR_DROPOFF': 'POR ENTREGAR',
    'COMPLETED': 'FINALIZADA'
  };

  const confirmCancel = () => {
    Modal.confirm({
      title: "¿Estás segúro de cancelar esta orden?",
      content: "Esta acción no se podrá deshacer.",
      okText: "Cancelar orden",
      okType: "danger",
      cancelText: "No hacer nada",
      onOk() {
        cancelMonitorOrder();
      },
      onCancel() {},
    });
  };

  const confirmAcceptOrder = () => {
    Modal.confirm({
      title: "¿Estás segúro de colocar esta orden en preparación?",
      content: "Esta acción no se podrá deshacer.",
      okText: "Orden en preparación",
      cancelText: "Cancelar",
      onOk() {
        accepMonitorOrder();
      },
      onCancel() {},
    });
  };

  // console.log(order)

  const confirmUpdateOrder = () => {
    var title = "";
    if(orderType == "2") {
      title = "¿Confirmar esta orden lista para enviar?";
    } else {
      title = "¿Estás segúro de colocar esta orden lista para recoger?";
    }
    Modal.confirm({
      title: title,
      content: "Esta acción no se podrá deshacer.",
      okText: "Orden lista",
      cancelText: "Cancelar",
      onOk() {
        updateMonitorOrder();
      },
      onCancel() {},
    });
  };

  const confirmFinishOrder = () => {
    Modal.confirm({
      title: "¿Estás segúro de finalizar esta orden?",
      content: "Esta acción no se podrá deshacer.",
      okText: "Orden finalizada",
      cancelText: "Cancelar",
      onOk() {
        finishMonitorOrder();
      },
      onCancel() {},
    });
  };

  const accepMonitorOrder = async () => {
    handleSending(true);
    await acceptOrder();
    handleSending(false);
  };

  const updateMonitorOrder = async () => {
    handleSending(true);
    await updateOrder();
    handleSending(false);
  };

  const sendCirebaMonitorOrder = async () => {
    handleSending(true);
    await resendOrder();
    handleSending(false);
  };

  const openDataCard = async (id, data) => {
    handleLoadingOrder(true);
    let jsonobj = JSON.parse(data);
    Modal.info({
      title: 'JSON Tarjeta - ' + id,
      content: (
        <div>
          <pre dangerouslySetInnerHTML={{__html: syntaxHighlight(jsonobj)}} />
        </div>
      ),
      onOk() {},
    });

    handleLoadingOrder(false);
  };

  const syntaxHighlight = (json) => {
    if (typeof json != 'string') {
         json = JSON.stringify(json, undefined, 2);
    }
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
        var cls = 'number';
        if (/^"/.test(match)) {
            if (/:$/.test(match)) {
                cls = 'key';
            } else {
                cls = 'string';
            }
        } else if (/true|false/.test(match)) {
            cls = 'boolean';
        } else if (/null/.test(match)) {
            cls = 'null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
    });
  };

  const assingMonitorOrder = async () => {
    handleAssing(true);
    await assignOrder(selectDriver);
    handleAssing(false);
  };

  /* const updateNotesClient = async () => {
    setUpdatingNotes(true);
    await updateNotes(notes);
    setUpdatingNotes(false);
  };
  
  const transferMonitorOrder = async () => {
    handleAssing(true);
    await transferOrder(selectBranch);
    handleAssing(false);
  }; */

  const finishMonitorOrder = async () => {
    // openModalCommetaries("finish");
    handleSending(true);
    await finishOrder();
    handleSending(false);
  };

  const cancelMonitorOrder = async () => {
    // openModalCommetaries("cancel");
    handleDeleting(true);
    await cancelOrder();
    handleDeleting(false);
  };

  const redeemOrder = async() => {
    const request = await instance();
    let data = await request.post('/api/redimir-promo', {
      idOrden: order.id, 
      idBranch: user.branch,  
      idUser: user.id 
    })
    .then(res => {
      if(res.data.status !== 'success') return
      dispatch(
        getLastScannedThunk({ 
          id_user: user.id, 
          idBranch: user.branch 
        })
      );
      message.success('¡Código redimido exitosamente!')
      close()
    })
    .catch((error) => {
      return {
        error,
      };
    });
    return data;
  }

  /* const getInvoice = () => {
    return (
      <Row>
        <Col xs={24} lg={24}>
          <div className={styles.stepsWrap}>
            <span className={styles.labelSection}>FACTURA</span>
            <div className={styles.steps}>
              <div className={styles.step}>
                <span>Nombre:</span>
                <span>{info.nombre}</span>
              </div>
              <div className={styles.step}>
                <span>NIT:</span>
                <span>{info.nit}</span>
              </div>
              <div className={styles.step}>
                <span>Dirección:</span>
                <span>{info.direccion}</span>
              </div>
              <div className={styles.step}>
                <span>Giro:</span>
                <span>{info.giro}</span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }; */

  const Product = ({ product }) => {
    return (
      <div className={styles.product}>
        <span className={cx(styles.productName, styles.green)}>
          {`(${product.quantity}) ${product.name}`}&nbsp;&nbsp;
          { product.isCard && <button
          disabled={loadingDeta}
          className="btnGC"
          onClick={() => openDataCard(product.name, product.cardData)}
        >
          {loadingDeta ? <Spin size="small" /> : <SearchOutlined />}
        </button>}
        </span>
        <div className={styles.details}>
          {product.product_categories.map((category) => (
            <div key={category.id}>
              <span>{category.name}</span>
              {category.extras.map((extra) => (
                <span key={extra.id}>
                  <span className={styles.bullet}>•</span>
                  {extra.name}
                </span>
              ))}
            </div>
          ))}
        </div>
        <div className={styles.totalProduct}>
          <span className={styles.price}>
            {`${product.currency}${parseFloat(product.total).toFixed(2)}`}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.order}>
      <Row>
        <Col xs={24}>
          <div className={styles.header}>
            <span className={styles.title}>
              {`${
                status === 5 || status === 4
                  ? `Estatus de orden`
                  : `Detalle de pedido`
              }:`}
              <span className={styles.green}>{` ${getStatus()}`}</span>
            </span>
            <Close action={close} />
          </div>
        </Col>
      </Row>
      { orderType == "1" ?
        <Row>
          <Col xs={24}>
            <div className={styles.clientInfo}>
              <Row gutter={20}>
                <Col lg={7}>
                  <span className={styles.clientDetail}>
                    <span className={styles.labelClient}>CLIENTE:</span>
                    {`${id_user.name} ${id_user.lastname}`}
                  </span>
                </Col>
                <Col lg={7}>
                  <span className={cx(styles.clientDetail, styles.clientAddress)}>
                    <span className={styles.labelClient}>EMAIL:</span>
                    {id_user.email}
                  </span>
                </Col>
                <Col lg={4}>
                  <span
                    className={cx(
                      styles.clientDetail,
                      styles.clientPhone
                    )}
                  >
                    <span className={styles.labelClient}>TELÉFONO:</span>
                    {id_user.mobile}
                  </span>
                </Col>
                <Col lg={6}>
                  <span
                    className={cx(
                      styles.clientDetail,
                      styles.green,
                      styles.clientPhone
                    )}
                  >
                    <span className={styles.labelClient}>TIENDA:</span>
                    {branch.zone}
                  </span>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      :
        <Row>
          <Col xs={24}>
            <div className={styles.clientInfo}>
              <Row gutter={20}>
                <Col lg={7}>
                  <span className={styles.clientDetail}>
                    <span className={styles.labelClient}>CLIENTE:</span>
                    {`${id_user.name} ${id_user.lastname}`}
                  </span>
                </Col>
                <Col lg={9}>
                  <span className={cx(styles.clientDetail, styles.clientAddress)}>
                    <span className={styles.labelClient}>DIRECCIÓN:</span>
                    {delivery_address ? address_info.address : "N/A"}
                  </span>
                </Col>
                <Col lg={4}>
                  <span
                    className={cx(
                      styles.clientDetail,
                      styles.clientPhone
                    )}
                  >
                    <span className={styles.labelClient}>TELÉFONO:</span>
                    {id_user.phone}
                  </span>
                </Col>
                <Col lg={4}>
                  <span
                    className={cx(
                      styles.clientDetail,
                      styles.clientPhone
                    )}
                  >
                    <span className={styles.labelClient}>ZONA:</span>
                    {delivery_address ? address_info.zone.name : "N/A"}
                  </span>
                </Col>
              </Row>
              {
                delivery_address && address_info.latitude && 
                <Row gutter={20}>
                  <Col lg={4}>
                    <span className={styles.clientDetail}>
                      <span className={styles.labelClient}>UBICACION:</span>
                      <a href={`https://maps.google.com/?z=19&q=${address_info.latitude},${address_info.longitude}`} target="_blank" rel="noreferrer">ver mapa</a>
                    </span><br/>
                  </Col>
                  <Col lg={9}>
                    <span className={styles.clientDetail}>
                      <span className={styles.labelClient}>REFERENCIA:</span>
                      { address_info.reference_point }
                    </span>
                  </Col>
                  <Col lg={6}>
                    <span className={cx(styles.clientDetail, styles.clientAddress)}>
                      <span className={styles.labelClient}>NUMERO CASA:</span>
                      {address_info.number}
                    </span>
                  </Col>
                </Row>
              }
            </div>
          </Col>
        </Row>
      }
      {(!cireba_remote_id || cireba_remote_id === "0") && (order.status !== 6 && order.status !== 20) && (
        <div className={styles.warning}>
          <Alert 
            message="Orden no ingresada a CIREBA" 
            showIcon
            type="warning"
            action={ <></>
              /* <Button onClick={sendCirebaMonitorOrder} size="small" danger>
                Reintentar
              </Button> */
            }
             />
          
        </div>
      )}
      { /*
      (orderType == "2" && (!flotiOrder || flotiOrder === "0")) && (
        <div className={styles.warning}>
          <Alert message="Orden no ingresada a Floti" type="error" />
        </div>
      )
      */ }

      <div className={styles.body}>
        <Row>
          <Col xs={24}>
            <div className={styles.mainInfoWrap}>
              <span className={styles.ticket}>{`TICKET: ${ticket}`}</span>
              <a href={`tel:${id_user.mobile}`} className={styles.call}>
                LLAMAR
              </a>
            </div>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col xs={24} lg={12}>
            <div className={styles.products}>
              {products.map((product, index) => (
                <Product key={`${product.id}-${index}`} product={product} />
              ))}
            </div>
            {/* annotations && annotations.length > 0 && (
              <div className={styles.annotations}>
                <span>
                  <span className={styles.labelNotes}>Notas:</span>{" "}
                  {annotations}
                </span>
              </div>
            ) */}
            { orderType == "2" && (
               <div className={styles.totalInfo}>
               <div className={styles.deliveryWrap}>
                 <span className={styles.label}>ENVÍO A DOMICILIO:</span>
                 <span className={styles.price}>
                   {`${currency || 'C$'} ${parseFloat(delivery_fee).toFixed(2)}`}
                 </span>
               </div>
               {/* 
               <div className={styles.tipWrap}>
                 <span className={styles.label}>PROPINA PARA REPARTIDOR:</span>
                 <span className={styles.price}>
                   {`$ ${parseFloat(delivery_tip).toFixed(2)}`}
                 </span>
               </div>
               */}
              </div>
            ) 
            }
            { parseFloat(discount) > 0 && (
              <div className={styles.tipWrap}>
                 <span className={styles.label}>DESCUENTO:</span>
                 <span className={styles.price}>
                   {`- (${currency} ${parseFloat(discount).toFixed(2)})`}
                 </span>
               </div>
            )
            }
            <div className={styles.totalInfo}>
              <div className={styles.totalWrap}>
                <span className={styles.label}>TOTAL DE COMPRA</span>
                <span className={cx(styles.totalPrice, styles.green)}>
                  {`${currency} ${parseFloat(total).toFixed(2)}`}
                </span>
              </div>
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <Row>
              <Col xs={24}>
                <div className={styles.paymentWrap}>
                  <span className={styles.labelSection}>FORMA DE PAGO</span>
                  {isCash ? (
                    <div className={styles.methodWrap}>
                      <div className={styles.method}>
                        <Cash className={styles.methodIcon} />
                        Pago en efectivo
                      </div>
                      <span className={styles.cashback}>
                        {`A entregar: ${currency}${parseFloat(cashback).toFixed(
                          2
                        )} Cambio: ${currency}${parseFloat(change).toFixed(2)}`}
                      </span>
                    </div>
                  ) : (
                    <div className={styles.methodWrap}>
                      <div className={styles.method}>
                        <Card className={styles.methodIcon} />
                        Pago en tarjeta
                      </div>
                      <span className={styles.cashback}>
                        Valor: {`$${parseFloat(total).toFixed(2)}`}
                      </span>
                    </div>
                  )}
                </div>

                {order.status === 6 &&
                <div>
                  <Button 
                  className={styles.call}
                  onClick={redeemOrder}
                  >
                    REDIMIR
                  </Button>
                </div>}
              </Col>
            </Row>
            {/* monitor && isAdmin && status === 0 && (
              <Row>
                <Col xs={24}>
                  <div className={styles.selectDriverWrap}>
                    <span className={styles.labelSection}>
                      TRANSFERIR ORDEN
                    </span>
                    <Row gutter={20}>
                      <Col xs={15} lg={17}>
                        <Select
                          className={styles.selectDriver}
                          loading={loading}
                          disabled={loading}
                          showSearch
                          size="large"
                          placeholder="Seleccionar Tienda"
                          optionFilterProp="children"
                          value={selectBranch}
                          onChange={(e) => handleSelectedBranch(e)}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {branches.map((branch) => (
                            <Option key={branch.id} value={branch.id}>
                              {branch.name}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                      <Col xs={9} lg={7}>
                        <Button
                          disabled={loading}
                          onClick={transferMonitorOrder}
                          loading={assing}
                          size="large"
                          type="primary"
                          className={styles.driverAssign}
                        >
                          TRANSFERIR
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            ) */}
            {/* {invoice && getInvoice()} */}
            {/* extra_info && extra_info.length && (
              <div className={styles.reasonWrap}>
                <span className={styles.labelSection}>COMENTARIOS</span>
                <span>{extra_info}</span>
              </div>
            ) */}
            {!isDriver && orderType == "2" && peyaOrder == null && status >=8 && (
              <Row>
                <Col xs={24}>
                  <div className={styles.driverWrap}>
                    <span className={styles.labelSection}>DRIVER ASIGNADO</span>
                    <div className={styles.methodWrap}>
                      <div className={styles.method}>
                        <Driver className={styles.methodIcon} />
                        <span className={cx(styles.green, styles.driverName)}>
                          { order.id_driver ? `${order.id_driver.name} ` : "-" }
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            )}

            {monitor && !isDriver && orderType == "2" && status >= 7 && status < 8 && (
              <>
              { (!isCash) &&
                <Row>
                  <Col xs={24}>
                    <div className={styles.selectDriverWrap}>
                      <span className={styles.labelSection}>ASIGNAR DRIVER</span>
                      
                      <Button icon={<PeyaCourierLogo />} onClick={() => { sendPeya() }} size="large" >
                        <Space align="center">Enviar <DoubleRightOutlined /></Space>
                      </Button>
                    </div>
                    <Divider> o </Divider>
                  </Col>
                </Row>
                } 
                <Row>
                  <Col xs={24}>
                    <div className={styles.selectDriverWrap}>
                      { isCash && <span className={styles.labelSection}>ASIGNAR DRIVER</span> }
                      <Row gutter={20}>
                        <Col xs={15} lg={17}>
                          <Select
                            className={styles.selectDriver}
                            loading={loading}
                            disabled={loading}
                            showSearch
                            size="large"
                            placeholder="Seleccionar Driver"
                            optionFilterProp="children"
                            value={selectDriver}
                            onChange={(e) => handleSelectedDriver(e)}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {drivers.map((driver) => (
                              <Option key={driver.id} value={driver.id}>
                                {`${driver.name} ${driver.lastname}`}
                              </Option>
                            ))}
                          </Select>
                        </Col>
                        <Col xs={9} lg={7}>
                          <Button
                            disabled={loading}
                            onClick={assingMonitorOrder}
                            loading={assing}
                            size="large"
                            type="primary"
                            className={styles.driverAssign}
                          >
                            ASIGNAR PEDIDO
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </>
            )}
            {monitor && (
              <>
              {
                (peyaOrder) ?
                <Row>
                  <Col xs={24}>
                  <div className={styles.driverWrap}>
                      <span className={styles.labelSection}>Asignado a Pedidos Ya Courier</span>
                      <p>No. Confirmación: { peyaConfirmCode }</p>
                      <p>Estado del Pedido: <strong>{ spanish_peya[peyaStatus] }</strong> </p>
                  </div>
                    </Col>
                </Row>
                :
                <Row>
                  <Col xs={24}>
                    <div className={styles.submitWrap}>
                      {status < 12 && (
                        <Button
                          size="large"
                          type="primary" danger
                          loading={deleting}
                          className={cx(styles.accept, styles.reject)}
                          onClick={confirmCancel}
                        >
                          CANCELAR PEDIDO
                        </Button>
                      )}
                      {status === 6 && (
                        <Button
                          loading={sending}
                          size="large"
                          type="primary"
                          className={styles.accept}
                          onClick={confirmAcceptOrder}
                        >
                          ORDEN EN PREPARACIÓN
                        </Button>
                      )}
                      {status >= 7 && status < 10 && (
                        <Button
                          loading={sending}
                          size="large"
                          type="primary"
                          className={styles.accept}
                          onClick={confirmUpdateOrder}
                        >
                          { orderType=="1"?'ORDEN LISTA':'DESPACHAR ORDEN' }
                        </Button>
                      )}
                      {status >= 10 && status < 12 && (
                        <Button
                          loading={sending}
                          onClick={confirmFinishOrder}
                          size="large"
                          type="primary"
                          className={styles.accept}
                        >
                          ORDEN ENTREGADA
                        </Button>
                      )}
                      {isCash && status === 14 && (
                        <Button
                          loading={sending}
                          onClick={confirmUpdateOrder}
                          size="large"
                          type="primary"
                          className={styles.accept}
                        >
                          ORDEN EFECTIVO RECIBIDO
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              }
              </>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OrderDetail;
