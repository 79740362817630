import {Row, Col} from 'antd';
import moment from 'moment';
import cx from 'classnames';
import {ReactComponent as InPreparation} from '../assets/icons/inpreparation.svg';
import {ReactComponent as Driver} from '../assets/icons/driver.svg';
import {ReactComponent as Check} from '../assets/icons/check.svg';
import {ReactComponent as Cancel} from '../assets/icons/cancel.svg';
import styles from './styles/TableOrders.module.css';

const TableOrders = ({monitor, openDetails, orders}) => {
  const getIcon = status => {
    if (status === 7) {
      return <InPreparation className={styles.preparationIcon} />;
    } else if (status === 10) {
      return <Driver className={styles.driverIcon} />;
    } else if (status === 4) {
      return <Cancel className={styles.driverIcon} />;
    }
    return <Check />;
  };

  return (
    <div className={styles.table}>
      <div className={cx(styles.row, styles.header)}>
        <Row>
          <Col xs={6} md={4}>
            <div className={styles.cell}>
              <span>Ticket</span>
            </div>
          </Col>
          <Col xs={10} md={6}>
            <div className={cx(styles.cell, styles.left)}>
              <span>Usuario</span>
            </div>
          </Col>
          <Col xs={4} md={3}>
            <div className={styles.cell}>
              <span>Hora</span>
            </div>
          </Col>
          <Col xs={0} md={5}>
            <div className={styles.cell}>
              <span>Tienda</span>
            </div>
          </Col>
          <Col xs={0} md={3}>
            <div className={styles.cell}>
              <span>Monto</span>
            </div>
          </Col>
          <Col xs={4} md={3}>
            <div className={styles.cell}>
              <span>Estado</span>
            </div>
          </Col>
        </Row>
      </div>
      {orders.map(order => (
        <div
          key={order.id}
          className={cx(
            styles.row,
            styles.item,
            {
              [styles.onPreparation]: order.status === 7,
              [styles.onTheWay]: order.status === 11,
              [styles.canceled]: order.status === 4,
            }
          )}
          onClick={() => openDetails(order.id)}>
          <Row>
            <Col xs={6} md={4}>
              <div className={styles.cell}>
                <span>{order.ticket}</span>
              </div>
            </Col>
            <Col xs={10} md={6}>
              <div className={cx(styles.cell, styles.left)}>
                <span>{`${order.id_user.name} ${order.id_user.lastname}`}</span>
              </div>
            </Col>
            <Col xs={4} md={3}>
              <div className={styles.cell}>
                <span>{moment(order.created_time).format(monitor ? 'hh:mma' : 'DD/MM/YY - hh:mma')}</span>
              </div>
            </Col>
            <Col xs={0} md={5}>
              <div className={styles.cell}>
                <span>{order.Address.locality.name}</span>
              </div>
            </Col>
            <Col xs={0} md={3}>
              <div className={cx(styles.cell, styles.center)}>
                <span>{order.currency || 'C$'} {parseFloat(order.total).toFixed(2)}</span>
              </div>
            </Col>
            <Col xs={4} md={3}>
              <div className={cx(styles.cell, styles.center)}>
                {getIcon(order.status)}
              </div>
            </Col>
          </Row>
        </div>
      ))}
    </div>
  );
}

export default TableOrders;
