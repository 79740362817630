import { Tabs, Modal, Spin, Table, Tooltip, Col, Row } from "antd";
import { Link } from "react-router-dom";
import { getColumnSearchProps } from "../../../utils/tables";
import ProductCard from "./ProductCard";
import TableOrders from "../../../components/TableOrders";
import cx from "classnames";
import moment from "moment";
import { ReactComponent as History } from "../../../assets/icons/history.svg";
import { ReactComponent as Warning } from "../../../assets/icons/warning.svg";
import { ReactComponent as EyeBox } from "../../../assets/icons/eye-box.svg";
import { ReactComponent as Preparing } from "../../../assets/icons/preparing.svg";
import { ReactComponent as OrderList } from "../../../assets/icons/order-list.svg";
import { ReactComponent as AssignedDriver } from "../../../assets/icons/assigned-driver.svg";
import { ReactComponent as DriverInStore } from "../../../assets/icons/driver-in-store.svg";
import { ReactComponent as OnTheWay } from "../../../assets/icons/on-the-way.svg";
import { ReactComponent as RecentOrder } from "../../../assets/icons/recent-order.svg";
import { ReactComponent as TableIcon } from "../../../assets/icons/table.svg";
import { ReactComponent as Grid } from "../../../assets/icons/grid.svg";
import { ReactComponent as PreparingWhite } from "../../../assets/icons/preparing-white.svg";
import { ReactComponent as OrderListWhite } from "../../../assets/icons/order-list-white.svg";
import { ReactComponent as DriverWhite } from "../../../assets/icons/driver-white.svg";
import Header from "../../../components/HeaderList";
import OrderDetail from "../../../components/OrderDetail";
import styles from "../styles/MonitorUI.module.css";
import {  useState } from "react";
import OrderCard from "./OrderCard";

const { TabPane } = Tabs;

const NewMonitorUI = (props) => {
const {
    modalOrder,
    loadingOrder,
    closeDetails,
    openDetails,
    loaded,
    isDriver,
    // isAdmin,
    orderDetail,
    newOrders,
    dayOrders,
    // branches,
    acceptOrder,
    updateOrder,
    assignOrder,
    finishOrder,
    cancelOrder,
    resendOrder,
    sendPeya,
    getDriversBranch,
    openDriverConfirm,
    // closeModalCommetaries,
    // openModalCommetaries,
    // modalCommentaries,
    // transferOrder,
    // updateNotes,
    reasons
} = props;
const [view, setView] = useState(false);
const [currentStateOrder, setCurrentStateOrder] = useState(null);
const [orderType, setOrderType] = useState(-1);
const getIconForStatus11Card = (orderType) => {
    return (orderType=== 1 ||orderType=== 0)? <OrderListWhite /> : <DriverWhite />
}
const getIconForStatus11Table = (orderType) => {
    return (orderType=== 1 ||orderType=== 0)? <OrderList /> : <OnTheWay />
}
const getIcon = (status,orderType ,type) => {
    if (status === 7) {
    return (
        <Tooltip title="Preparando" placement="top">
        {type === "card" ? <PreparingWhite /> : <Preparing />}
        </Tooltip>
    );
    } else if (status === 8) {
    return (
        <Tooltip title="Driver asignado" placement="top">
        {type === "card" ? <DriverWhite /> : <AssignedDriver />}
        </Tooltip>
    );
    } else if (status === 9) {
    return (
        <Tooltip title="Driver en tienda" placement="top">
        {type === "card" ? <DriverWhite /> : <DriverInStore />}
        </Tooltip>
    );
    } else if (status === 10) {
    return (
        <Tooltip title="Orden lista" placement="top">
        {type === "card" ? <OrderListWhite /> : <OrderList />}
        </Tooltip>
    );
    } else if (status === 11) {
    return (
        <Tooltip title={ (orderType===1 ||orderType=== 0)?"lista para recoger":"En camino"} placement="top">
        {type === "card" ? getIconForStatus11Card(orderType) : getIconForStatus11Table(orderType)}
        </Tooltip>
    );
    }
    return (
    <Tooltip title="Orden nueva" placement="top">
        <RecentOrder />
    </Tooltip>
    );
};






if (!loaded) {
    return (
    <div className={cx(styles.view, styles.loader)}>
        <Spin size="large" />
    </div>
    );
}
const orderTypes = [
    { value: -1, text: "Todos", items: dayOrders.length },
    {
        value: 0,
        text: "Pedidos ya",
        items: dayOrders.filter((item) => item.orderType === 0 )
        .length,
    },
    {
        value: 1,
        text: "Take out",
        items: dayOrders.filter((item) => item.orderType === 1)
        .length,
    },
    {
        value: 2,
        text: "Delivery",
        items: dayOrders.filter((item) => item.orderType === 2)
        .length,
    },
]

const orderStates = [
    {
    value: 6,
    text: "Orden nueva",
    items: dayOrders.filter((x) => x.status === 6).length,
    },
    {
    value: 7,
    text: "Preparando",
    items: dayOrders.filter((x) => x.status === 7).length,
    },
    {
    value: 8,
    text: "Driver Asignado",
    items: dayOrders.filter((x) => x.status === 8).length,
    },
    {
    value: 9,
    text: "Driver en tienda",
    items: dayOrders.filter((x) => x.status === 9).length,
    },
    {
    value: 10,
    text: "Orden lista",
    items: dayOrders.filter((x) => x.status === 10).length,
    },
    {
    value: 11,
    text: (orderType===1 ||orderType=== 0)? "lista para recoger" : "En camino",
    items: dayOrders.filter((x) => x.status === 11).length,
    },
];
const columns = [
    {
    title: "Origen",
    dataIndex: "platform",
    align: "center",
    },
    {
    title: "Ticket",
    dataIndex: "ticket",
    ...getColumnSearchProps("ticket"),
    render: (val, record) => (
        <span>
        {(!record.cireba_remote_id || record.cireba_remote_id === "0") && (
            <Tooltip placement="top" title="Orden no ingresada a CIREBA">
            <Warning className={styles.warning} />
            </Tooltip>
        )}
        {val}
        </span>
    ),
    },
    {
    title: "Cliente",
    dataIndex: "id_user__fullname",
    ...getColumnSearchProps("id_user__fullname"),
    },
    {
    title: "Sucursal",
    dataIndex: "branch",
    align: "center",
    render: (val) => val.zone || "-",
    ...getColumnSearchProps("branch_name"),
    },
    {
    title: "Hora de ingreso",
    dataIndex: "created_time",
    align: "center",
    render: (val) => moment(val).format("hh:mm a"),
    },
    {
    title: "Teléfono",
    dataIndex: "id_user",
    align: "center",
    render: (val) => val.mobile,
    ...getColumnSearchProps("id_user__mobile"),
    },
    {
    title: "Monto actual",
    dataIndex: "total",
    align: "center",
    render: (val) => `$${val}`,
    },
    {
    title: "Estado",
    dataIndex: "status",
    align: "center",
    filters: orderStates,
    onFilter: (value, record) => record.status === value,
    render: (val,record) => getIcon(val,record.orderType),
    },
    {
    title: "Tipo de Orden",
    dataIndex: "orderType",
    align: "center",
    filters: [
        { value: 0, text: "PEDIDOS YA" },
        { value: 1, text: "PARA LLEVAR" },
        { value: 2, text: "DOMICILIO" },
    ],
    onFilter: (value, record) => record.orderType === value,
    render: (value) => (value === 0 ? "PEDIDOS YA" : value === 1 ? "PARA LLEVAR" : "DOMICILIO" ),
    },
    {
    title: "Ver detalle",
    dataIndex: "id",
    align: "center",
    render: (val) => (
        <button
        disabled={loadingOrder}
        onClick={() => openDetails(val)}
        className={styles.eye}
        >
        {loadingOrder === val ? <Spin size="small" /> : <EyeBox />}
        </button>
    ),
    },
];

const filterDayOrders = () => {
    if(orderType !== -1 && currentStateOrder !== null){
    return dayOrders.filter((x) => x.orderType === orderType && x.status === currentStateOrder );
    }
    if(orderType === -1 && currentStateOrder === null){
    return dayOrders
    }
    if(orderType === -1 && currentStateOrder !== null){
    return dayOrders.filter((x) => x.status === currentStateOrder);
    }

    if(orderType !== -1 && currentStateOrder === null){
    return dayOrders.filter((x) => x.orderType === orderType);
    }
};
return (
    <div className={styles.view}>
    {!isDriver ? (
        <Row>
        <Col span={24}>
            <Header
            title="Ordenes Activas"
            actions={
                <div className={styles.ordersButtons}>
                
                <Link to="/drivers" className={styles.history}>
                    <span> 📡 DRIVERS EN LINEA</span>
                </Link>
                <Link to="/historial" className={styles.history}>
                    <History />
                    <span>VER HISTORIAL DE ORDENES</span>
                </Link>
                </div>
            }
            />
            <div className={styles.containerIconViews}>
            <span className={styles.icons}>
                <TableIcon onClick={() => setView(false)} />
                <Grid onClick={() => setView(true)} />
            </span>
            </div>
            {view ? (
            <>
            <div className={styles.containerStates}>
                {orderTypes.map((item,index) => (
                <span
                    key={index}
                    className={[
                    styles.state,
                    orderType === item.value
                        ? styles.stateActive
                        : styles.stateInactive,
                    ].join(" ")}
                    onClick={() => {
                    if(item.value === -1){
                        setCurrentStateOrder(null)
                    }
                    setOrderType(item.value)
                    } }
                >
                    {item.text}
                    <span
                    className={[
                        styles.badge,
                        orderType === item.value
                        ? styles.badgeActive
                        : styles.badgeInactive,
                    ].join(" ")}
                    >
                    {item.items}
                    </span>
                </span>
                ))}
            </div> 
            
                <div className={styles.containerStates}>
                {
                orderStates
                .map((item,index) => (
                    <span
                    key={index}
                    className={[
                        styles.state,
                        currentStateOrder === item.value
                        ? styles.stateActive
                        : styles.stateInactive,
                    ].join(" ")}
                    onClick={() => setCurrentStateOrder(item.value)}
                    >
                    {item.text}
                    <span
                        className={[
                        styles.badge,
                        currentStateOrder === item.value
                            ? styles.badgeActive
                            : styles.badgeInactive,
                        ].join(" ")}
                    >
                        {item.items}
                    </span>
                    </span>
                ))}
                </div>
                <div className={styles.containerOrders}>
                {filterDayOrders().map((item) => (
                    <OrderCard key={item.id} item={item} getIcon={getIcon} loadingOrder={loadingOrder} openDetails={openDetails} />
                ))}
                </div>
            </>
            ) : (
            <Table columns={columns} dataSource={dayOrders} rowKey="id" />
            )}
        </Col>
        </Row>
    ) : (
        <Row>
        <Col span={24}>
            <div className={styles.tabsSummaryWrap}>
            <Tabs defaultActiveKey="1">
                <TabPane tab="ORDENES ACTIVAS" key="1">
                {newOrders.length ? (
                    newOrders.map((order) => (
                    <ProductCard
                        key={order.id}
                        order={order}
                        openDetails={openDetails}
                    />
                    ))
                ) : (
                    <div className={styles.empty}>
                    <span>SIN ORDENES NUEVAS</span>
                    </div>
                )}
                </TabPane>
                <TabPane tab="HISTORIAL" key="2">
                {dayOrders.length ? (
                    <TableOrders
                    monitor
                    orders={dayOrders}
                    openDetails={openDetails}
                    />
                ) : (
                    <div className={styles.empty}>
                    <span>NO SE HAN ENCONTRADO ORDENES</span>
                    </div>
                )}
                </TabPane>
            </Tabs>
            </div>
        </Col>
        </Row>
    )}

    
    <Modal
        destroyOnClose
        wrapClassName={styles.modalOrder}
        footer={false}
        open={modalOrder}
        width={1150}
        closable={false}
        onCancel={closeDetails}
    >
        <OrderDetail
        order={orderDetail}
        monitor
        close={closeDetails}
        // branches={branches}
        acceptOrder={acceptOrder}
        updateOrder={updateOrder}
        assignOrder={assignOrder}
        finishOrder={finishOrder}
        cancelOrder={cancelOrder}
        resendOrder={resendOrder}
        sendPeya={sendPeya}
        getDriversBranch={getDriversBranch}
        openDriverConfirm={openDriverConfirm}
        // openModalCommetaries={openModalCommetaries}
        // transferOrder={transferOrder}
        // updateNotes={updateNotes}
        reasons={reasons}
        />
    </Modal>
    </div>
);
};

export default NewMonitorUI;  